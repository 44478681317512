import { ThemeMode } from 'components/MUI/theme';
import {
  AISCOUT_CLUB_ID,
  BURNLEY_CLUB_ID,
  CHELSEA_CLUB_ID,
  MAHD_SPORTS_ACADEMY_ID,
} from 'helpers/constants';
import { AiSCOUTRole, UserGroup } from 'server/context';
import { signInPageURL } from 'auth/constants';

export interface PageLink {
  title: string;
  link: string;
  roles: AiSCOUTRole[] | 'All';
  turkGroup?: UserGroup;
  defaultTheme?: ThemeMode;
  invisibleLink?: boolean; // Those pages are not visible in the sidebar because they require a specific parameter to be passed in the url
  supportsBothThemes?: boolean;
  clubsVisibleList?: number[];
}

interface SidebarMenu {
  title: string;
  image: string;
  roles: AiSCOUTRole[] | 'All';
  turkGroup?: UserGroup;
  submenu: PageLink[];
  forDevs?: boolean;
  clubsVisibleList?: number[];
}

const SidebarMenuJSON: SidebarMenu[] = [
  {
    title: 'Turk',
    image: '/images/sidebar/turk.svg',
    roles: ['9:AiSCOUTStaff', '11:Turk'],
    turkGroup: 'Turk',
    submenu: [
      {
        title: 'Standard Turk',
        link: '/turk',
        roles: ['9:AiSCOUTStaff', '11:Turk'],
        turkGroup: 'Turk',
        defaultTheme: 'light',
        supportsBothThemes: false,
      },
      {
        title: 'Benchmark Turk',
        link: '/turk?turkPageType=benchmark',
        roles: ['9:AiSCOUTStaff', '11:Turk'],
        turkGroup: 'BenchmarkTurk',
        defaultTheme: 'light',
        supportsBothThemes: false,
      },
      {
        title: 'Turk Queue',
        link: '/turkqueue',
        roles: ['9:AiSCOUTStaff', '11:Turk'],
        turkGroup: 'TurkAdmin',
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Super Turk Trial List',
        link: '/superturktriallist',
        roles: ['9:AiSCOUTStaff', '11:Turk'],
        turkGroup: 'TurkAdmin',
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Turk Error Codes',
        link: '/turkerrorcodes',
        roles: ['9:AiSCOUTStaff', '11:Turk'],
        turkGroup: 'TurkAdmin',
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
    ],
  },
  {
    title: 'Recruitment Centre',
    image: '/images/sidebar/recruitment-centre.svg',
    roles: [
      '1:AiSCOUTAppointedPerson',
      '2:HeadOfRecruitment',
      '3:HeadScout',
      '4:LocalOrRegionalScout',
      '9:AiSCOUTStaff',
      '10:Scout',
      '14:MLSNext',
      '15:RecruitmentCentre',
    ],
    submenu: [
      // TODO: homepage should have somewhat a special interface
      {
        title: 'HomePage',
        link: '/',
        roles: 'All',
        defaultTheme: 'light',
        invisibleLink: true,
        supportsBothThemes: true,
      },
      {
        title: 'Sign In',
        link: signInPageURL,
        roles: [],
        defaultTheme: 'light',
        invisibleLink: true,
        supportsBothThemes: false,
      },
      {
        title: 'Discover',
        link: '/discover',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '10:Scout',
          '14:MLSNext',
          '15:RecruitmentCentre',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Leaderboards',
        link: '/leaderboards',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '10:Scout',
          '14:MLSNext',
          '15:RecruitmentCentre',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Player Management',
        link: '/playermanagement',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '14:MLSNext',
          '15:RecruitmentCentre',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Player Comparison',
        link: '/playercompare',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '10:Scout',
          '14:MLSNext',
          '15:RecruitmentCentre',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Settings',
        link: '#', // TODO: we should have a dedicated prop for links in the development phase
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Player Profile',
        link: '/playerprofile',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '10:Scout',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
        invisibleLink: true,
      },
    ],
  },
  {
    title: 'Academy Analysis',
    image: '/images/sidebar/academy-analysis.svg',
    roles: [
      '1:AiSCOUTAppointedPerson',
      '2:HeadOfRecruitment',
      '3:HeadScout',
      '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
      '9:AiSCOUTStaff',
      '14:MLSNext',
    ],
    submenu: [
      {
        title: 'Session Data',
        link: '/academysessiondata',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
          '9:AiSCOUTStaff',
          '14:MLSNext',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Squad List',
        link: '/academysquadlist',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '9:AiSCOUTStaff',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Academy Player Profile',
        link: '/academyplayerprofile',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
          '9:AiSCOUTStaff',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
        invisibleLink: true,
      },
      {
        title: 'aiLab Reports',
        link: '/ailabsreportdashboard',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'light',
        supportsBothThemes: false,
        invisibleLink: false,
      },
      {
        title: 'Statistics',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Leaderboards',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Player Comparison',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Analysis Builder Template',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Settings',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
    ],
  },
  {
    title: 'Scout Hub',
    image: '/images/sidebar/scout-hub.svg',
    roles: [
      '1:AiSCOUTAppointedPerson',
      '2:HeadOfRecruitment',
      '3:HeadScout',
      '4:LocalOrRegionalScout',
      '9:AiSCOUTStaff',
      '10:Scout',
    ],
    submenu: [
      {
        title: 'Scout Profile',
        link: '/scoutprofile',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '10:Scout',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Scout Reports',
        link: '/scoutreports',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '10:Scout',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'New Player Reports',
        link: '/newplayerreport',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '10:Scout',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
        invisibleLink: true,
      },
      {
        title: 'Game Centre',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Maps and Reports',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Player Report',
        link: '/playerreport',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '10:Scout',
        ],
        invisibleLink: true,
        defaultTheme: 'light',
        supportsBothThemes: false,
      },
      {
        title: 'Player Match Report',
        link: '/matchreport',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '4:LocalOrRegionalScout',
          '9:AiSCOUTStaff',
          '10:Scout',
        ],
        invisibleLink: true,
        defaultTheme: 'light',
        supportsBothThemes: false,
      },
    ],
  },
  {
    title: 'Trial Centre',
    image: '/images/sidebar/trial-centre.svg',
    roles: ['9:AiSCOUTStaff'],
    submenu: [
      {
        title: 'Trial Builder Template',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Active Trials',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Expired Trials',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
    ],
  },
  {
    title: 'Commercial Area',
    image: '/images/sidebar/commercial-area.svg',
    roles: [
      '1:AiSCOUTAppointedPerson',
      '2:HeadOfRecruitment',
      '3:HeadScout',
      '5:LeadOrHeadCommercial',
      '6:CommercialStaff',
      '9:AiSCOUTStaff',
    ],
    submenu: [
      {
        title: 'Sign-ups',
        link: '/commercialsignups',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '5:LeadOrHeadCommercial',
          '6:CommercialStaff',
          '9:AiSCOUTStaff',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Maps and Location Reports',
        link: '/commercialmapsreports',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '5:LeadOrHeadCommercial',
          '6:CommercialStaff',
          '9:AiSCOUTStaff',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Drills Completed',
        link: '/commercialdrillscompleted',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '5:LeadOrHeadCommercial',
          '6:CommercialStaff',
          '9:AiSCOUTStaff',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Carbon Emissions',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
    ],
  },
  {
    title: 'Document Store',
    image: '/images/sidebar/document-store.svg',
    roles: ['9:AiSCOUTStaff'],
    submenu: [
      {
        title: 'Contracts and Invoices',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Logos and Branding',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'App Images and Mock Ups',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Approved Media',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Playbook',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
      {
        title: 'Training Manuals',
        link: '#',
        roles: ['9:AiSCOUTStaff'],
      },
    ],
  },
  {
    title: 'Performance Lab',
    image: '/images/sidebar/performance-lab.svg',
    roles: [
      '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
      '8:SportScienceStaff',
      '9:AiSCOUTStaff',
      '12:AiLabsUser',
      '13:AiLabsExternalStaff',
    ],
    clubsVisibleList: [AISCOUT_CLUB_ID, CHELSEA_CLUB_ID, BURNLEY_CLUB_ID],
    submenu: [
      {
        title: 'Station Leaderboard',
        link: '/performancelableaderboard',
        roles: ['9:AiSCOUTStaff', '13:AiLabsExternalStaff'],
        defaultTheme: 'dark',
        supportsBothThemes: true,
      },
      {
        title: 'Metric Leaderboard',
        link: '/metricleaderboard',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'dark',
        supportsBothThemes: true,
      },
      {
        title: 'Attendees',
        link: '/performancelabattendees',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'dark',
        supportsBothThemes: true,
      },
      {
        title: 'Station Data Entry',
        link: '/stationdataentry',
        roles: [
          '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
          '8:SportScienceStaff',
          '9:AiSCOUTStaff',
          '13:AiLabsExternalStaff',
        ],
        defaultTheme: 'dark',
        clubsVisibleList: [AISCOUT_CLUB_ID, CHELSEA_CLUB_ID, BURNLEY_CLUB_ID],
        supportsBothThemes: true,
      },
      {
        title: 'Maturation Data Entry',
        link: '/maturationdataentry',
        roles: [
          '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
          '8:SportScienceStaff',
          '9:AiSCOUTStaff',
          '13:AiLabsExternalStaff',
        ],
        defaultTheme: 'dark',
        clubsVisibleList: [AISCOUT_CLUB_ID, CHELSEA_CLUB_ID, BURNLEY_CLUB_ID],
        supportsBothThemes: true,
      },
      {
        title: 'Configure Lab Sessions',
        link: '/configurelabsessions',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'dark',
        supportsBothThemes: true,
      },
      {
        title: 'Add New Trialist',
        link: '/addnewtrialist',
        roles: [
          '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
          '8:SportScienceStaff',
          '9:AiSCOUTStaff',
        ],
        defaultTheme: 'dark',
        clubsVisibleList: [AISCOUT_CLUB_ID, CHELSEA_CLUB_ID, BURNLEY_CLUB_ID],
        supportsBothThemes: true,
      },
      {
        title: 'Cognitive Assessment',
        link: '/cognitiveassessment',
        roles: ['9:AiSCOUTStaff', '12:AiLabsUser'],
        defaultTheme: 'dark',
        supportsBothThemes: true,
      },
      {
        title: 'Cognitive Test',
        link: '/cognitivetest',
        roles: ['9:AiSCOUTStaff', '12:AiLabsUser'],
        defaultTheme: 'dark',
        supportsBothThemes: true,
        invisibleLink: true,
      },
    ],
  },
  {
    title: 'Admin',
    image: '/images/sidebar/admin.svg',
    roles: [
      '1:AiSCOUTAppointedPerson',
      '2:HeadOfRecruitment',
      '3:HeadScout',
      '9:AiSCOUTStaff',
    ],
    submenu: [
      {
        title: 'Access Rights List',
        link: '/accessrightslist',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '9:AiSCOUTStaff',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Access Rights Management',
        link: '/accessrightsmanagement',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '9:AiSCOUTStaff',
        ],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Signed Player Management',
        link: '#',
        roles: [
          '1:AiSCOUTAppointedPerson',
          '2:HeadOfRecruitment',
          '3:HeadScout',
          '9:AiSCOUTStaff',
        ],
      },
      {
        title: 'Club Settings',
        link: 'clubsettings',
        roles: ['1:AiSCOUTAppointedPerson', '9:AiSCOUTStaff'],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Club Setup',
        link: 'clubsetup',
        roles: ['9:AiSCOUTStaff'],
        clubsVisibleList: [AISCOUT_CLUB_ID],
      },
    ],
  },
  {
    title: 'App content',
    image: '/images/sidebar/mobile-app-update-icon.svg',
    roles: ['9:AiSCOUTStaff', '15:RecruitmentCentre'],
    submenu: [
      {
        title: 'Configure Pro Club',
        link: '/configureproclub',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Affiliation Codes',
        link: '/affiliationcodes',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Add Affiliation Codes',
        link: 'addaffiliationcodes',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Player Affiliation Codes',
        link: 'playeraffiliationcodes',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'light',
        invisibleLink: true,
        supportsBothThemes: true,
      },
      {
        title: 'Upload Drill Entry',
        link: '/drillentryupload',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'light',
        supportsBothThemes: true,
      },
      {
        title: 'Bulk player upload',
        link: '/bulkplayerupload',
        roles: ['9:AiSCOUTStaff', '15:RecruitmentCentre'],
        defaultTheme: 'light',
        supportsBothThemes: true,
        clubsVisibleList: [AISCOUT_CLUB_ID, MAHD_SPORTS_ACADEMY_ID],
      },
    ],
  },
  {
    title: 'Reports',
    image: '/images/sidebar/report.svg',
    roles: ['9:AiSCOUTStaff'],
    clubsVisibleList: [AISCOUT_CLUB_ID],
    submenu: [
      {
        title: 'Report Dashboard',
        link: '/reportdashboard',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'light',
        supportsBothThemes: true,
        clubsVisibleList: [AISCOUT_CLUB_ID],
      },
      {
        title: 'aiScout Reports',
        link: '/aiscoutreportdashboard',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'light',
        supportsBothThemes: true,
        clubsVisibleList: [AISCOUT_CLUB_ID],
      },
    ],
  },
  {
    title: 'Development',
    image: '/images/sidebar/embed-code-icon.svg',
    roles: ['9:AiSCOUTStaff'],
    submenu: [
      {
        title: 'Performance Lab Report Page',
        link: '/performancelabreport',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'dark',
        invisibleLink: true,
        supportsBothThemes: false,
      },
      {
        title: 'Performance Chart Board Page',
        link: '/performancechartboard',
        roles: ['9:AiSCOUTStaff'],
        defaultTheme: 'dark',
        invisibleLink: true,
        supportsBothThemes: false,
      },
      {
        title: 'Redesign Components',
        link: '/redesigncomponents',
        roles: 'All',
        defaultTheme: 'light',
        supportsBothThemes: false,
      },
    ],
    forDevs: true,
  },
];

export default SidebarMenuJSON;
