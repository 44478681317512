import { Box } from '@mui/material';
import axios from 'axios';
import { env } from 'env.mjs';
import { useSnackbar } from 'notistack';
import React from 'react';

import { brandonFontFamily, robotoFontFamily } from 'components/MUI/typography';
import RedCross from 'components/shared/Icons/RedCross';
import { trpc } from 'helpers/trpc';
import { signInPageCallbackSearchParamName } from 'auth/constants';

interface Props {
  portrait?: boolean;
  label: number | string;
  smallText?: boolean;
  deviceName: string;
  url: string;
  onSubmit: () => void;
}

const ScreenInfo = ({
  portrait,
  label,
  smallText,
  deviceName,
  onSubmit,
  url,
}: Props) => {
  const { data: AllCurrentScreenStates } =
    trpc.shared.getLabScreenStates.useQuery(undefined, {
      refetchInterval: 3000,
    });

  const { enqueueSnackbar } = useSnackbar();

  const currentScreen =
    (AllCurrentScreenStates &&
      AllCurrentScreenStates.find(
        (screen) => screen.deviceName === deviceName
      )) ??
    null;

  const active = Boolean(currentScreen);

  const handleOnScreenClick = async () => {
    try {
      await axios.post(
        `${env.NEXT_PUBLIC_SCREEN_API_BASE_URL}/setScreenToURL`,

        {
          deviceName,
          url,
        }
      );

      enqueueSnackbar('Screen Cast', { variant: 'success' });
      onSubmit();
    } catch {
      if (active) {
        enqueueSnackbar('Error Whilst Casting', { variant: 'error' });
      } else {
        enqueueSnackbar('Screen not connected', { variant: 'warning' });
      }
    }
  };

  const handleOnScreenCancel = async (event: React.MouseEvent) => {
    event.stopPropagation();

    try {
      await axios.post(`${env.NEXT_PUBLIC_SCREEN_API_BASE_URL}/clearScreen`, {
        deviceName,
      });

      enqueueSnackbar('Screen Cleared', { variant: 'success' });
    } catch {
      if (active) {
        enqueueSnackbar('Error Whilst Clearing Screen', { variant: 'error' });
      } else {
        enqueueSnackbar('Screen not connected', { variant: 'warning' });
      }
    }
  };

  const styles = {
    castScreenCard: {
      position: 'relative',
      border: '1px solid #8C8C8C',
      borderRadius: '4px',
      m: 1,
      flex: portrait ? 2 : 1,
    },
    castScreenContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
      cursor: active ? 'pointer' : 'not-allowed',
      opacity: active ? 1 : 0.25,
    },
    castScreenContentHeader: {
      fontFamily: brandonFontFamily,
      fontSize: smallText ? '24px' : '48px',
      py: active ? 0 : 3,
      lineHeight: 1,
    },
    castScreenContentDescription: {
      fontSize: '14px',
      fontFamily: robotoFontFamily,
      maxWidth: '120px',
      px: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 1,
    },
  };

  const returnPageName = (link: string) => {
    let actualURL = link;

    // Check if the link is a redirect one after login. If so, extract the callback URL
    if (
      link.includes(signInPageCallbackSearchParamName) &&
      link.includes('/api/auth/callback/')
    ) {
      try {
        actualURL = new URL(link).searchParams.get('callbackUrl') ?? link;
      } catch {
        // NOOP
      }
    }

    const currentHost = window.location.host;

    try {
      const parsedUrl = new URL(actualURL);
      if (parsedUrl.host === currentHost) {
        return parsedUrl.pathname.split('/').at(-1) || '/';
      }
      return `${parsedUrl.host}${parsedUrl.pathname}`;
    } catch (proccessCurrentCastUrlError) {
      console.error('Invalid URL:', proccessCurrentCastUrlError);
      return '';
    }
  };

  const screenIdentifier = (
    <Box sx={styles.castScreenContent}>
      <Box sx={styles.castScreenContentHeader}>{label}</Box>
      <Box sx={styles.castScreenContentDescription}>
        {currentScreen &&
          currentScreen.lastURLReceived &&
          returnPageName(currentScreen.lastURLReceived)}
      </Box>
    </Box>
  );

  return (
    <Box sx={styles.castScreenCard} onClick={handleOnScreenClick}>
      {active && <RedCross onClick={handleOnScreenCancel} />}
      {screenIdentifier}
    </Box>
  );
};

export default ScreenInfo;
